import { bubble as Menu } from "react-burger-menu";
import AnchorLink from "react-anchor-link-smooth-scroll-v2";

const MenuComponent = () => {
  const showSettings = (event) => {
    event.preventDefault();
  };
  return (
    <Menu>
      <AnchorLink href="#about">About Me</AnchorLink>
      <AnchorLink href="#youtube">Youtube</AnchorLink>
      <AnchorLink href="#facebook">Facebook</AnchorLink>
      <AnchorLink href="#instagram">TikTok & Instagram</AnchorLink>
    </Menu>
  );
};

export default MenuComponent;
