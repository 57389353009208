import React from "react";
import { Canvas } from "@react-three/fiber";

import MenuComponent from "./Menu";
import Banner from "./Banner";
import Description from "./Description";
import Work from "./Work";
import HireMe from "./HireMe";
import Footer from "./Footer";

import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader";
import { useLoader } from "@react-three/fiber";

import { useEffect } from "react";
import { OrbitControls, Bounds, BakeShadows } from "@react-three/drei";
import CanvasContainer from "./CanvasContainer";

const Main = ({ data }) => {
  return (
    <>
      {/*<CanvasContainer />*/}
      <div id="outer-container">
        <MenuComponent bubble outerContainerId={"outer-container"} />
      </div>
      <Banner />
      <Description />
      <Work data={data} />
      <HireMe />
      <Footer />
    </>
  );
};

export default React.memo(Main);
