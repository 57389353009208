const HireMe = () => {
  return (
    <section id="hireme" className="section hireme" id={"contact"}>
      <div className="container">
        <div className="col-md-8 col-md-offset-2 text-center">
          <h3>LOOKING FOR SOMETHING SPECIFIC?</h3>
          {/*<p>*/}
          {/*  We are currently crafting new products but would love to hear from*/}
          {/*  you.*/}
          {/*</p>*/}
          {/*<a href="contact.html" className="btn btn-large">*/}
          {/*  Contact me*/}
          {/*</a>{" "}*/}
          <button
            className="btn btn-large"
            onClick={() => (window.location = "mailto:belgrppg@gmail.com")}
          >
            Contact Me
          </button>
        </div>
      </div>
    </section>
  );
};

export default HireMe;
