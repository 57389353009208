const Footer = () => {
  return (
    <footer className="section footer">
      <div className="footer-bottom">
        <div className="container">
          <div className="col-md-12">
            <p>
              <br />
              Made with ♥️ by{" "}
              <a href={"https://ineedityesterday.dev"}>I need it Yesterday</a>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
