import { InstagramEmbed, YouTubeEmbed } from "react-social-media-embed";
import React from "react";

const Work = ({ data }) => {
  const instagram = data["INSTAGRAM"];
  const facebook = data["FACEBOOK"];
  const youtube = data["YOUTUBE"];
  const tiktok = data["TIKTOK"];

  return (
    <>
      <section id="works" className="works section no-padding" id={"work"}>
        <h1>My Work</h1>
        <div className={"youtube"} id="youtube">
          <h2>Youtube</h2>
          <LoadImage type="youtube" array={youtube} />
        </div>
        <div className={"facebook"} id="facebook">
          <h2>Facebook</h2>
          <LoadImage type="facebook" array={facebook} />
        </div>
        <div className={"instagram"} id="instagram">
          <h2>Instagram</h2>
          <LoadImage type="instagram" array={instagram} />
        </div>
      </section>
    </>
  );
};

export default Work;

function LoadImage({ type, array }) {
  let indexo = 0;

  return (
    <>
      <div className={"wrapper"} key={type + Math.random()}>
        {Object.keys(array).map(function (key, index) {
          return (
            <>
              {Object.keys(array[key]).map(function (k, i) {
                indexo = indexo + 1;
                return (
                  <>
                    <img
                      key={`/videos/${type}-${indexo}.png+${k}+${i}+${index}`}
                      className={"youtube-img"}
                      src={`/videos/${type}-${indexo}.png`}
                      onClick={() =>
                        window.open(
                          array[key][i],
                          "_blank",
                          "noopener, noreferrer"
                        )
                      }
                    />
                  </>
                );
              })}
            </>
          );
        })}
      </div>
    </>
  );
}
