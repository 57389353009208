const Description = () => {
  return (
    <section id="descripton" className="section descripton" id={"about"}>
      <div className="container">
        <div className="text-center">
          <h2>About Me</h2>
          <p>
            I live in São Paulo and have a bachelor's degree in Design, but
            throughout my life I've always been doing what I most enjoy doing:
            videos!
          </p>
          <p>
            I have extensive experience in social media, such as YouTube,
            Facebook, Instagram and Tik Tok. I have always been a very musical
            person, I play some instruments, but it was through visual media
            that my passion merged with my work. So I also have in my portfolio
            some music videos that I made from scratch.{" "}
          </p>
          <p>
            There is a lot about me here on this website, so feel free to ask me
            if you have any questions. Other than that, I like to create, to
            solve problems, to be with people. And there is no challenge that I
            can't take on!
          </p>
        </div>
      </div>
    </section>
  );
};

export default Description;
