import { useEffect, useState } from "react";

import data from "./utils/videos.json";

import "./styles/animate.min.css";
import "./styles/main.css";
import "./styles/bootstrap.min.css";
import "./styles/responsive.css";
import "./styles/jquery.fancybox.css";
import "./App.css";
import Main from "./components/Main";

function App() {
  return <div className="App">{data && <Main data={data} />}</div>;
}

export default App;
